import React from "react";
import styled from "styled-components";
//assets
import { vendorLogosList } from "../../../helper/constant";
import LogoBackground from "../../../images/Logobackground.svg";

const Wrapper = styled.div`
	max-width: 1280px;
	width: 67%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	overflow: hidden;

    @media screen and (max-width: 500px) {
        width: 100%;
        max-width: 100%;
        justify-content: space-evenly;
    }

	@media screen and (min-width: 900px) and (max-width: 1199px) {
		width: 83%;
	}

	@media screen and (min-width: 1200px) and (max-width: 1280px) {
		width: 75%;
	}
	@media screen and (min-width: 1280px) and (max-width: 1400px) {
		width: 75%;
		margin-inline: auto;
	}

	@media screen and (min-width: 1785px) {
		width: 60%;
		margin-right: 23rem;
	}

	@media screen and (min-width: 2550px) {
		width: 72%;
		margin-inline: auto;
	}
`;

const Link = styled.a`
	text-decoration: none;
	cursor: pointer;
	margin-inline: 8vh;

	@media screen and (max-width: 500px) {
		margin-inline: 6vh;
	}
`;

const CompanyLogo = styled.img`
	transform: scale(1.4);
	@media screen and (max-width: 500px) {
		transform: scale(1.1);
	}
`;

const Container = styled.div`
	width: 100%;
	background-image: url(${LogoBackground});
`;

function VendorLogos() {
	return (
		<Container>
			<Wrapper>
				{vendorLogosList.map((logo) => (
					<Link
						href={logo.link}
						target="_blank"
						rel="noreferrer"
						key={logo.id}
						style={{
							margin: "1.2rem 3vw 1.2rem 3vw",
							height: "2rem"
						}}
						className="animeContainer"
					>
						<CompanyLogo src={logo.logo} alt={logo.name} loading="lazy" />
					</Link>
				))}
			</Wrapper>
		</Container>
	);
}

export default VendorLogos;
