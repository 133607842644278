import React from 'react';
import { StyledRequiredText, StyledRequiredTextContainer } from '../style';
import { Link } from 'gatsby';

const Required = () => {
    return (
        <StyledRequiredTextContainer>
            <StyledRequiredText>
                <span>*</span>Only available if the user is verified using Government ID
                Verification, Knowledge Based Authentication, or SSN Verification.
            </StyledRequiredText>
            <StyledRequiredText>
                <span>**</span>Varies court to court. Refer to{' '}
                <Link
                    to="https://docs.authenticate.com/reference/post_identity-user-search-counties-1"
                    target="_blank"
                    rel="noreferrer"
                >
                    County History
                </Link>{' '}
                for pricing.
            </StyledRequiredText>
        </StyledRequiredTextContainer>
    );
};

export default Required;
