import React from "react";
import { Helmet } from "react-helmet";

function SEO() {
	return (
		<div>
			<Helmet>
				<meta charset="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
				<title>
					Identity Authentication & Background Verification API & SDK
				</title>
				<meta
					name="keywords"
					content="Age Check API, Age Verification API, Background Verification API, Background Verification SDK, Criminal Background Check API, Criminal Conviction Reporting, Driver license Verification API, Education Verification API, Employment Verification API, ID Verification & Background Check Cloud, Identity Authentication, Identity Verification API, KBA API, Knowledge Based Authentication API, Knowledge Based Authentication Quiz, Knowledge Based Authentication Solutions, License Verification API, Email Verification, Evictions API, Facial Recognition API, Global Watch List Report, Multi-Factor Verifications, National Criminal Check, Passport Authentication, Professional License Verification, SMS Verification, SSN Verification, US Corporation or Business Verification, Verify US Passport Data, Verify US Photo ID Data"
				/>
				<meta
					name="description"
					content="Authenticate.com is a verification platform that provides Knowledge Based Authentication, Photo ID & Passport Verification, Age, Employment, Education & Criminal Background Checks, MVR & DMV Records, Email, SMS, FEIN & SSN Verification."
				/>

				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<meta
					name="description"
					content="Identity Authentication & Background Checks."
				/>
				<meta name="robots" content="index, follow, archive" />
				<meta
					property="st:section"
					content="Identity Authentication & Background Check API & SDK for Custom Multi-Factor Verifications."
				/>
				<meta
					property="og:image"
					content={
						"https://cdn.authenticating.com/public/website/logos/authenticate-main-open-graph-image.png"
					}
				/>
				<meta
					name="twitter:title"
					content="Identity Authentication & Background Check API"
				/>
				<meta
					name="twitter:description"
					content="Identity Authentication & Background Checks"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta property="og:url" content="https://authenticate.com/" />
				<meta
					property="og:title"
					content="Identity Authentication & Background Checks"
				/>
				<meta
					property="og:description"
					content="Identity Authentication & Background Checks"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:locale" content="en_US" />
				<meta
					property="og:site_name"
					content="Identity Authentication, ID Verification & Background Checks"
				/>
				<title>Identity Authentication & Background Checks </title>
				<link
					rel="shortcut icon"
					href={
						"https://u.jimcdn.com/cms/o/s27d67650be169598/img/favicon.png?t=1498234019"
					}
					type="image/x-icon"
				/>
				<link rel="canonical" href="https://authenticate.com/"></link>
			</Helmet>
		</div>
	);
}

export default SEO;
