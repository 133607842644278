import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Heading, SubText } from './components/styles';
import { maxWidth } from '../../styles/screenSizes';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';

import Authenticate_mini from '../../images/logos/authenticate_mini.svg';
import AuthenticateLogo from '../../components/AuthenticateLogo';
import MetaVerifyLogo from '../../components/MetaVerifyLogo';

const Container = styled.section`
    background: linear-gradient(270deg, #0083b0 0.01%, #00b4db 100%);
    padding: 84px 0 48px;
    margin: 65px 0px 0px 0px;
    overflow: hidden;
    height: 100vh;

    @media screen and (max-width: 820px) {
        padding: 33px 20px 20px;
        height: auto;
    }
`;

const ContentSection = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: space-between;
    gap: 3rem;

    @media screen and (max-width: 1250px) {
        padding: 0 24px;
    }

    @media screen and (max-width: 820px) {
        padding: 0;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
`;

const GridContainer = styled.div`
    margin-top: ${(props) => (props.last ? 'none' : '40px')};
    @media screen and (max-width: 820px) {
        margin: 0 auto;
        text-align: center;
    }
`;

const SecondGridContainer = styled(GridContainer)`
    height: 85vh;
    overflow-y: scroll;
    scrollbar-width: none;
    pointer-events: none;

    ::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 820px) {
        height: 100%;
        overflow-y: visible;
        margin-bottom: 20px;
        pointer-events: auto;
    }
`;

const BtnGroup = styled.div`
    margin-top: 36px;
`;

const Button = styled.a`
    text-decoration: none;
    font-family: 'Rubik';
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 6px;
    background: #fff;
    color: #212121;
    border: none;
    cursor: pointer;
    margin-right: ${(props) => (props.margin ? '24px' : '0')};
    display: inline-block;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.1);
    }

    ${({ secondary }) =>
        secondary &&
        css`
            border: 1px solid #fff;
            background: none;
            color: #fff;
        `};
`;

const InfoGroup = styled.div`
    margin-top: ${(props) => (props.first ? '600px' : '95px')};

    @media screen and (max-width: 820px) {
        margin-top: 20px;
    }
`;

const ImageContainer = styled.div`
    width: 44px;
    height: 44px;
    padding: 10px;
    background: #fff;
    border-radius: 50%;
    margin-bottom: 16px;

    @media screen and (max-width: 820px) {
        margin: 0 auto 24px;
    }
`;

const Image = styled.img`
    width: 24px;
    height: 24px;
`;

const UiHeading = styled(Heading)`
    color: #fff;
    font-size: ${({ secondary }) => (secondary ? '32px' : '56px')};

    span {
        font-size: 40px;
        background: linear-gradient(105deg, #e0eeff -20.97%, rgba(157, 202, 255, 0.53) 141.2%);

        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }

    @media screen and (max-width: 820px) {
        font-size: ${(props) => (props.small ? '30px' : '24px')};

        span {
            font-size: 24px;
        }
    }
`;

const ExtraDiv = styled.div`
    height: 100px;

    @media screen and (max-width: 820px) {
        display: none;
    }
`;

const UiSubText = styled(SubText)`
    color: #fff;
    text-align: left;
    max-width: 100%;
    font-size: ${({ info }) => (info ? '24px' : '32px')};

    @media screen and (max-width: 820px) {
        font-size: ${(props) => (props.small_screen ? '14px' : '16px')};
        text-align: center;
    }
`;

export const MetaVerifySvg = styled.span`
    display: inline-flex;
    align-self: center;
    margin-bottom: -12.5%;
    color: #212121;

    img {
        height: 44px;
        width: auto;
    }

    @media screen and (max-width: ${maxWidth.s}) {
        img {
            height: 26px;
            width: auto;
        }
    }
`;

function VerifyUi() {
    const containerRef = useRef(null);
    const scrollRef = useRef(null);

    gsap.registerPlugin(ScrollTrigger);

    useIsomorphicLayoutEffect(() => {
        let ref1 = null;

        ScrollTrigger.matchMedia({
            '(min-width: 1000px)': function () {
                ref1 = gsap.to(scrollRef.current, {
                    scrollTo: scrollRef.current.scrollHeight - scrollRef.current.offsetHeight,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: scrollRef.current,
                        pin: '#' + containerRef.current.id,
                        start: 'top top+=150px',
                        // end:
                        // 	"top+=" +
                        // 	scrollRef.current.scrollHeight -
                        // 	scrollRef.current.offsetHeight,
                        end: 'top+=2000',
                        anticipatePin: 1,
                        pinType: 'fixed',
                        scrub: 0.5,
                        pinSpacing: true,
                    },
                });
            },
        });

        return function cleanup() {
            if (ref1) {
                if (ref1.scrollTrigger) ref1.scrollTrigger.kill();

                ref1.kill();
            }
        };
    }, []);

    return (
        <Container id="reveal-container" ref={containerRef}>
            <ContentSection>
                <GridContainer>
                    <ImageContainer>
                        <Image src={Authenticate_mini} alt="Authenticating" loading="lazy" />
                    </ImageContainer>
                    <UiHeading small>
                        <MetaVerifySvg>
                            <MetaVerifyLogo />
                        </MetaVerifySvg>
                        <br />
                        Works Worldwide!
                    </UiHeading>
                    <UiSubText small small_screen info margin="16px" style={{ maxWidth: '90%' }}>
                        We're able to authenticate practically anyone, anywhere. And Medallion™ is
                        easier to use than any other solution.
                    </UiSubText>
                    <BtnGroup>
                        <Button margin href="#contact">
                            Contact Us
                        </Button>
                        <Button
                            secondary
                            href="https://verify.authenticating.com/onboarding"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AuthenticateLogo />
                        </Button>
                    </BtnGroup>
                </GridContainer>
                <SecondGridContainer last id="scroll-container" ref={scrollRef}>
                    <InfoGroup first>
                        <UiHeading secondary>
                            <span>6,500+</span> Passports & IDs Worldwide
                        </UiHeading>
                        <UiSubText info margin="12px">
                            Instant forensic analysis of 6,500+ government IDs from 200 countries in
                            38 languages.
                        </UiSubText>
                    </InfoGroup>
                    <InfoGroup>
                        <UiHeading secondary>
                            <span>24/7/365</span> Expert Manual Review
                        </UiHeading>
                        <UiSubText info margin="12px">
                            Optional facial recognition, active & passive liveness detection & a
                            24/7/365 fallback method of an AI-assisted, expert manual review.
                        </UiSubText>
                    </InfoGroup>
                    <InfoGroup>
                        <UiHeading secondary>
                            <span>$0</span> Setup Fee & No Long Term Contract
                        </UiHeading>
                        <UiSubText info margin="12px">
                            Get started with a $100 deposit for utilization credit. Draw down your
                            balance over time and auto-replenish when empty.
                        </UiSubText>
                    </InfoGroup>
                    <InfoGroup>
                        <UiHeading secondary>
                            <span>40</span> Countries with Database Checks
                        </UiHeading>
                        <UiSubText info margin="12px">
                            You can also use personal knowledge quizzes or a social security number
                            trace in the U.S, or database verifications in 40 countries.
                        </UiSubText>
                    </InfoGroup>
                    <ExtraDiv></ExtraDiv>
                </SecondGridContainer>
            </ContentSection>
        </Container>
    );
}

export default VerifyUi;
